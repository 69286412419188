<template>
  <div>
    <el-form :inline="true">
      <!-- 搜索区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
      <!-- 角色 -->
      <el-form-item>
        <el-dropdown split-button type="primary" @command="getbyroles">
          {{ $t('xt.juese') }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">{{
              $t('xt.quanbu')
            }}</el-dropdown-item>
            <el-dropdown-item command="1">{{ $t('xt.gly') }}</el-dropdown-item>
            <el-dropdown-item command="2">{{ $t('xt.kefu') }}</el-dropdown-item>
            <el-dropdown-item command="3">{{
              $t('xt.yonghu')
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
      <!-- 身份标识 -->
      <el-form-item>
        <el-dropdown split-button type="primary" @command="getbyisreal">
          {{ $t('xt.sfbs') }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="2">{{
              $t('xt.quanbu')
            }}</el-dropdown-item>
            <el-dropdown-item command="1">{{ $t('xt.zsyh') }}</el-dropdown-item>
            <el-dropdown-item command="0">{{ $t('xt.xnyh') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="managelist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <el-table-column prop="is_real" :label="$t('xt.sfbs')">
        <template slot-scope="{ row }">
          <span v-if="row.is_real === '1'">{{ $t('xt.zsyh') }}</span>
          <span v-else>{{ $t('xt.xnyh') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" :label="$t('xt.fczh')">
        <template slot-scope="{ row }">
          <span v-if="row.status === '1'">{{ $t('fou') }}</span>
          <span v-else>{{ $t('shi') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="group_id" :label="$t('xt.juese')">
        <template slot-scope="{ row }">
          <span v-if="row.group_id">{{
            roleslist[row.group_id - 1].name
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createtime"
        :label="$t('xt.zcsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column
        prop="lastlogintime"
        :label="$t('xt.zjycdlsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" width="150px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('xt.czmm')"
            placement="top"
            :enterable="false"
          >
            <i
              class="iconfont icon-zhongzhimima"
              @click="resetpassword(row.id)"
            ></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 编辑管理员弹窗 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="editform"
        label-width="180px"
        ref="editform"
        :rules="editrules"
      >
        <el-form-item :label="$t('yhm')" prop="username">
          <el-input v-model="editform.username"></el-input>
        </el-form-item>
        <el-form-item :label="$t('xt.sfbs')" prop="isreal">
          <el-radio-group v-model="editform.isreal">
            <el-radio label="1">{{ $t('xt.zsyh') }}</el-radio>
            <el-radio label="0">{{ $t('xt.xnyh') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('xt.fczh')" prop="status">
          <el-radio-group v-model="editform.status">
            <el-radio label="0">{{ $t('shi') }}</el-radio>
            <el-radio label="1">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1,
        isreal: 2,
        roles: 0
      },
      total: 0,
      loading: true,
      // 角色对象
      roleslist: [],
      // 管理员列表
      managelist: [],
      // 编辑弹窗
      editdialogvisible: false,
      // 编辑对象
      editform: {
        username: '',
        isreal: '',
        status: '',
        id: ''
      },
      // 编辑验证表规则对象
      editrules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        isreal: [
          { required: true, message: '请选择身份标识', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择是否封存账号', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      this.loading = true
      const { data } = await this.$http.get('/admin/system/managelist', {
        params: this.queryinfo
      })
      this.managelist = data.data.managelist
      this.roleslist = data.data.roleslist
      this.total = +data.data.count
      this.loading = false
    },
    // 角色筛选
    getbyroles(command) {
      this.queryinfo.roles = command
      this.getlist()
    },
    // 身份标识筛选
    getbyisreal(command) {
      this.queryinfo.isreal = command
      this.getlist()
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 编辑打开弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/system/editmanage/id/' + id)
      if (data) {
        if (data.code === 200) {
          this.editform.id = data.data.id
          this.editform.isreal = data.data.is_real
          this.editform.status = data.data.status
          this.editform.username = data.data.username
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
      loading.close()
    },
    // 重置密码
    resetpassword(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('queding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/user/resetpassword', {
          id: id
        })
        if (data) {
          if (data.code === 200) {
            this.$alert('重置后密码为111111')
          } else {
            this.$message.error(data.msg)
          }
        }
      })
    },
    // 格式化时间
    dateFormat(row, column) {
      const time = row[column.property]
      if (!time) {
        return '-'
      }
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 关闭编辑弹窗
    editclose() {
      this.editdialogvisible = false
      this.$refs.editform.resetFields()
    },
    // 提交弹窗
    async editsubmit() {
      const { data } = await this.$http.post(
        '/admin/system/editmanage',
        this.editform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.getlang(data.msg))
          this.getlist()
          this.editclose()
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-pagination {
  margin-top: 15px;
  text-align: center;
}
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.el-select {
  min-width: 300px;
}
</style>
